// Area Chart
.chart-area {
  position: relative;
  width: 100%;
}

// Bar Chart
.chart-bar {
  position: relative;
  width: 100%;
}

// Pie Chart
.chart-pie {
  position: relative;
  width: 100%;
}
