.house-plan-level-1 {
  background: url($house-plan-level-1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 700px;
  min-width: 350px;
  max-width: 500px;
  text-align: center;
  vertical-align: middle;
  margin: 0 auto;

  .row > div {
    height: 174px;
    display: flex;

    span {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0 4px;

      i {
        font-size: 30px;
      }
    }

    .data-box-ambient {
      .title {
        justify-content: center;
        font-weight: bold;
        font-size: 18px;
        background: gray;
        color: white;
        padding: 0 5px;
        border-radius: 5px;
      }
    }
  }
}

.sidebarsa,
.topbadasdr {
  .nav-item {
    // Customize Dropdown Arrows for Navbar
    &.dropdown {
      .dropdown-toggle {
        &::after {
          width: 1rem;
          text-align: center;
          float: right;
          vertical-align: 0;
          border: 0;
          font-weight: 900;
          content: "\f105";
          font-family: "Font Awesome 5 Free";
        }
      }
      &.show {
        .dropdown-toggle::after {
          content: "\f107";
        }
      }
    }
    // Counter for nav links and nav link image sizing
    .nav-link {
      position: relative;
      .badge-counter {
        position: absolute;
        transform: scale(0.7);
        transform-origin: top right;
        right: 0.25rem;
        margin-top: -0.25rem;
      }
      .img-profile {
        height: 2rem;
        width: 2rem;
      }
    }
  }
}
